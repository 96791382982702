.iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    background-color: #ededed;
    border-radius: 14px;
}

.iconContainer:hover {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border-radius: 14px;
    background-color: #000;
}

.iconContainer .icon {
    color: #000;
    font-size: 10px;
}

.iconContainer:hover .icon {
    color: #fff;
    font-size: 10px;
}

.dayBoxDesktop {
    width: 250px;
    max-width: 350px;
    height: 35px;
    margin-inline: 7px;
    border-radius: 5px;
    color: #1a1a2a;
    background-color: "#f8f8f8",
}

.dayBoxDesktop:focus {
    width: 250px;
    max-width: 350px;
    height: 35px;
    margin-inline: 7px;
    border-radius: 5px;
    color: #000;
    border: 1px solid #000;
    background-color: "#f8f8f8",
}

.dayBoxDesktop:hover {
    width: 250px;
    max-width: 350px;
    height: 35px;
    margin-inline: 7px;
    border-radius: 5px;
    border: 1px solid #383838;
    color: #000;
    background-color: "#f8f8f8",
}

.dayBoxDesktop-selected {
    width: 250px;
    max-width: 350px;
    height: 35px;
    margin-inline: 7px;
    border-radius: 5px;
    border: 2px solid #000;
    color: #000;
    cursor: not-allowed;
    background-color: "#f8f8f8",
}

.dayBoxDesktop-selected:hover {
    width: 250px;
    max-width: 350px;
    height: 35px;
    margin-inline: 7px;
    border-radius: 5px;
    border: 2px solid #000;
    color: #000;
    cursor: not-allowed;
    background-color: "#f8f8f8",
}

.dayBoxDesktop-selected:focus {
    width: 250px;
    max-width: 350px;
    height: 35px;
    margin-inline: 7px;
    border-radius: 5px;
    border: 2px solid #000;
    color: #000;
    pointer-events: none;
    background-color: "#f8f8f8",
}


.dayBoxMobile {
    min-width: 100px;
    height: 30px;
    border-radius: 5px;
    margin-inline: 5px;
    border: 1px solid #e0e0e0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-weight: "bold"
}

.dayBoxMobile:focus {
    min-width: 100px;
    height: 30px;
    border-radius: 5px;
    margin-inline: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    border: 1.5px solid #000;
}

.dayBoxMobile:hover {
    min-width: 100px;
    height: 30px;
    border-radius: 5px;
    margin-inline: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    border: 1.5px solid #000;
}

.dayBoxMobile-selected {
    min-width: 100px;
    height: 30px;
    border-radius: 5px;
    margin-inline: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    border: 1.5px solid #000;
    cursor: not-allowed;
}

.dayBoxMobile-selected:hover {
    min-width: 100px;
    height: 30px;
    border-radius: 5px;
    margin-inline: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    border: 1.5px solid #000;
    pointer-events: none;
}

.dayBoxMobile-selected:focus {
    min-width: 100px;
    height: 30px;
    border-radius: 5px;
    margin-inline: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    border: 2px solid #000;
    pointer-events: none;
}

.dayBoxMobileContainer {
    justify-content: center;
    width: 100%;
    max-width: 1250px;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    padding-inline: 5px;
    background-color: "#f8f8f8",
}

/* Hide scrollbar for Chrome, Safari and Opera */
.dayBoxMobileContainer::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.dayBoxMobileContainer {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}