* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.bottom-nav {
    width: 100%;
    min-height: 55px;
    height: 3rem;
    background-color: #f8f9fa;
    display: flex;
    z-index: 2;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    border-top: 1px solid #ddd;
}

.bn-tab {
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bn-tab-selected {
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 3px solid #000;
}