body {
  height: 100%;
  background-color: #fff;
  font-family: "DINNextLTPro-Regular", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 18px;
  -ms-text-size-adjust: 18px;
}

@font-face {
  font-family: "DINNextLTPro-Regular";
  font-display: swap;
  src: local("DINNextLTPro-Regular"),
    url("../public/assets/fonts/Regular/DINNextLTPro-Regular.woff2") format("woff2"),
    url("../public/assets/fonts/Regular/DINNextLTPro-Regular.woff") format("woff");
}

@font-face {
  font-family: "DINNextLTPro-Bold";
  font-display: swap;
  src: local("DINNextLTPro-Bold"),
    url("../public/assets/fonts/Bold/DINNextLTPro-Bold.woff2") format("woff2"),
    url("../public/assets/fonts/Bold/DINNextLTPro-Bold.woff") format("woff");
}

@font-face {
  font-family: "DINNextLTPro-Heavy";
  font-display: swap;
  src: local("DINNextLTPro-Heavy"),
    url("../public/assets/fonts/Heavy/DINNextLTPro-Heavy.woff2") format("woff2"),
    url("../public/assets/fonts/Heavy/DINNextLTPro-Heavy.woff") format("woff");
}


.ant-spin-dot-item {
  background-color: black;
}

span {
  font-family: "DINNextLTPro-Regular";
}

.ant-tabs {
  background: #fff;
}

.ant-tabs-ink-bar {
  background: #000 !important;
}

.ant-tabs-top>.ant-tabs-nav::before {
  /* For the line to the right and close to the tabs */
  border-width: 1px;
  border-color: transparent;
}

.ant-tabs>.ant-tabs-nav {
  /* So that there is no gap between the content and tabs */
  margin: 0px;
}

.navbar-menu p {
  margin: 0;
}

.navbar-menu .ant-tabs-ink-bar {
  /* background: transparent !important; */
}

.navbar-menu .ant-tabs {
  margin: 0px;
}


.ant-drawer-body {
  flex-grow: 1;
  padding: 24px 0;
  overflow: auto;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}


[ant-click-animating-without-extra-node='true']::after {
  display: none;
}

.ant-timeline-item-head {
  background-color: transparent !important;
}

.showLess {
  background-color: transparent;
  color: #000;
}

.showLess:hover {
  background-color: transparent;
  color: #000;
  border-color: #000;
}

.ant-table {
  border: "1px solid #eff0f2",
}

.ant-table .ant-table-container .ant-table-content table thead.ant-table-thead .ant-table-cell {
  background-color: #f8f8f8;
}

.table-striped-rows {
  border-bottom: none !important;
  overflow: hidden;
}

.table-striped-rows td:last-child {
  border-bottom: none !important;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
}

.table-striped-rows:hover {
  background-color: #f5f5fa;
}

.table-striped-rows:focus {
  background-color: #f5f5fa;
}

.table-striped-rows:focus-visible {
  background-color: #f5f5fa;
}